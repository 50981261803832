//import './assets/main.css'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './index.css'
import './firebaseInit'
import 'bootstrap-icons/font/bootstrap-icons.css'
import * as Sentry from "@sentry/vue";

// Define a plugin for the console wrapper
function ConsoleWrapperPlugin(app) {

    const isDebugInProduction = false;
    const isProduction = import.meta.env.MODE === 'production';

    // Define global properties for logging
    app.config.globalProperties.$print = {
        log: (...args) => {
            if (!isProduction || isDebugInProduction) {
                console.log(...args);
            }
        },
        error: (...args) => {
            if (!isProduction || isDebugInProduction) {
                console.error(...args);
            } else {
                // Optionally, send these to a server-side logging service
            }
        },
        warn: (...args) => {
            if (!isProduction || isDebugInProduction) {
                console.warn(...args);
            }
        }
    };
}

const app = createApp(App)

// Initialize Sentry
if (!(import.meta.env.MODE === 'development')) {
    Sentry.init({
        app,
        dsn: "https://bb88d30d753478aae8787b764e076c37@o4507176685928448.ingest.us.sentry.io/4507176688287744",
        integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}
  
app.use(ConsoleWrapperPlugin);
app.use(store)
app.use(router)

/*router.isReady().then(() => {
    app.mount('#app');
  });
*/

app.mount('#app')

