import { createRouter, createWebHistory } from 'vue-router'
import { firebaseAuth } from '@/firebaseInit';
import { onAuthStateChanged } from "firebase/auth";


const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 }
  },
  routes: [
    {
      path: '/signin',
      name: 'signin',
      component: () => import('../views/Signin.vue'),
      meta: { requiresAuth: false, skipIfAuth: '/AIBatchProcess' },
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('../views/Profile.vue'),
      meta: { bothWebAndOfficeAddin: true, requiresAuth: '/AIBatchProcessLanding' },
    },
    {
      path: '/purchaseCredits',
      name: 'purchaseCredits',
      component: () => import('../views/PurchaseCredits.vue'),
      meta: { bothWebAndOfficeAddin: true, requiresAuth: '/AIBatchProcessLanding' },
    },
    {
      path: '/selectPlan',
      name: 'selectPlan',
      component: () => import('../views/SelectPlan.vue'),
      meta: { bothWebAndOfficeAddin: true, requiresAuth: '/AIBatchProcessLanding' },
    },
    {
      path: '/creditsAddedSuccess',
      name: 'creditsaddedsuccess',
      component: () => import('../views/CreditsAddedSuccess.vue'),
      meta: { bothWebAndOfficeAddin: true, requiresAuth: '/AIBatchProcessLanding' },
    },
    {
      path: '/subscriptionSuccess',
      name: 'subscriptionSuccess',
      component: () => import('../views/SubscriptionSuccess.vue'),
      meta: { bothWebAndOfficeAddin: true, requiresAuth: '/AIBatchProcessLanding' },
    },
    {
      path: '/AIBatchProcessLanding',
      name: 'AIBatchProcessLanding',
      component: () => import('../views/AIBatchProcess/AIBatchProcessLanding.vue'),
      meta: { requiresAuth: false, skipIfAuth: '/AIBatchProcess' },
    },
    {
      path: '/AIBatchProcess',
      name: 'AIBatchProcess',
      component: () => import('../views/AIBatchProcess/AIBatchProcess.vue'),
      meta: { requiresAuth: '/AIBatchProcessLanding' },
    },
    {
      path: '/AIBatchProcessHistory',
      name: 'AIBatchProcessHistory',
      component: () => import('../views/AIBatchProcess/AIBatchProcessHistory.vue'),
      meta: { requiresAuth: '/AIBatchProcessLanding' },
    },
    {
      path: '/AIBatchProcessHelp',
      name: 'AIBatchProcessHelp',
      component: () => import('../views/AIBatchProcess/AIBatchProcessHelp.vue'),
      meta: { requiresAuth: '/AIBatchProcessLanding' },
    },
    {
      path: '/signinOfficeAddin',
      name: 'signinOfficeAddin',
      component: () => import('../views/SigninOfficeAddin.vue'),
      meta: { bothWebAndOfficeAddin: true, requiresAuth: false },
    },
    {
      path: '/signinOfficeAddinSuccess',
      name: 'signinOfficeAddinSuccess',
      component: () => import('../views/SigninOfficeAddinSuccess.vue'),
      meta: { requiresAuth: '/AIBatchProcessLanding' },
    },
    {
      path: '/signoutOfficeAddinSuccess',
      name: 'signoutOfficeAddinSuccess',
      component: () => import('../views/SignoutOfficeAddinSuccess.vue'),
      meta: { requiresAuth: '/AIBatchProcessLanding' },
    },
    {
      path: '/AIBatchProcessExcelAddin',
      name: 'AIBatchProcessExcelAddin',
      component: () => import('../views/AIBatchProcess/AIBatchProcessExcelAddin.vue'),
      meta: { isOfficeAddin: true, requiresAuth: '/AIBatchProcessExcelAddinLanding', },
    },
    {
      path: '/AIBatchProcessExcelAddinHelp',
      name: 'AIBatchProcessExcelAddinHelp',
      component: () => import('../views/AIBatchProcess/AIBatchProcessExcelAddinHelp.vue'),
      meta: {isOfficeAddin: true, requiresAuth: '/AIBatchProcessExcelAddinLanding' },
    },  
    {
      path: '/AIBatchProcessExcelAddinLanding',
      name: 'AIBatchProcessExcelAddinLanding',
      component: () => import('../views/AIBatchProcess/AIBatchProcessExcelAddinLanding.vue'),
      meta: { isOfficeAddin: true, requiresAuth: false, skipIfAuth: '/AIBatchProcessExcelAddin' },
    },
    {
      path: '/:pathMatch(.*)*', // Catch-all route for 404 Page Not Found
      redirect: '/AIBatchProcessLanding',
    },
    {
      path: '/termsOfService',
      name: 'TermsOfService',
      component: () => import('../views/TermsOfService.vue'),
      meta: { bothWebAndOfficeAddin: true, dontShowNavSidebar: true, title: 'Terms of Service', requiresAuth: false }
    },
    {
      path: '/privacy',
      name: 'Privacy',
      component: () => import('../views/Privacy.vue'),
      meta: { bothWebAndOfficeAddin: true,  dontShowNavSidebar: true, title: 'Privacy', requiresAuth: false }
    },
    {
      path: '/getOpenAIAPIKeyHelp',
      name: 'getOpenAIAPIKeyHelp',
      component: () => import('../views/GetOpenAIAPIKeyHelp.vue'),
      meta: { bothWebAndOfficeAddin: true, requiresAuth: '/AIBatchProcessLanding' },
    },
  ]
})

function getCurrentUser() {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, (user) => {
      unsubscribe();
      resolve(user);
    }, reject);
  });
}


router.beforeEach(async (to, from, next) => {
  //const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  //const skipIfAuth = to.matched.some(record => record.meta.skipIfAuth);
  const requiresAuth = to.matched.find(record => record.meta.requiresAuth)?.meta.requiresAuth;
  const skipIfAuth = to.matched.find(record => record.meta.skipIfAuth)?.meta.skipIfAuth;
  const isAuthenticated = await getCurrentUser();

  if (requiresAuth && !isAuthenticated) {
    //next('/AIBatchProcessLanding');
    next(requiresAuth)
  } else if (skipIfAuth && isAuthenticated) {
    //next('/AIBatchProcess');
    next(skipIfAuth);    
  } else {  
    next();
  }
});

export default router
