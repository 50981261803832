import { createStore } from 'vuex'

export default createStore({
    state() {
      return {
        userCredits: 0, // Initial value of credits
        user: null,
        userCurrentPlan: "",
        isSubscriptionActive: false,
        userAPIKeyLastFourDigits:'',
        isOfficeJSLoaded: false,
        isOfficeAddin: false,
      };
    },
    mutations: {

      /*changeCredits(state, amount) {
        state.userCredits += amount;
      },*/

      setUserCredits(state, newCredits) {
        state.userCredits = newCredits;
      },

      setUser(state, newUser) {
        state.user = newUser;
      },

      setUserCurrentPlan(state, newCurrentPlan) {
        state.userCurrentPlan = newCurrentPlan;
      },

      setIsSubscriptionActive(state, newIsSubscriptionActive) {
        state.isSubscriptionActive = newIsSubscriptionActive;
      },

      setUserAPIKeyLastFourDigits(state, newUserAPIKeyLastFourDigits) {
        state.userAPIKeyLastFourDigits = newUserAPIKeyLastFourDigits;
      },

      setIsOfficeJSLoaded(state, newIsOfficeJSLoaded) {
        state.isOfficeJSLoaded = newIsOfficeJSLoaded;
      },

      setIsOfficeAddin(state, newIsOfficeAddin) {
        state.isOfficeAddin = newIsOfficeAddin;
      },

    }
  });